import * as React from "react";
import { BooleanField, Datagrid, DateField, NumberField, RaRecord, TextField } from "react-admin";
import LocationChipField from "../customComponents/LocationChipField";
import { Coordinate, dateTimeOptionsDeCH, Props2String } from "./Common";
import { MissionNotificationType } from "./MissionStats";

export const MissionInfoStatsResource = "stats/missioninfos";

export const MissionInfoStatsDatagrid = () => (
    <Datagrid
        bulkActionButtons={false}
        rowStyle={() => ({height: "2.25rem"})}
    >
        <NumberField source={MissionInfoStatsProps.id}/>
        <TextField source={MissionInfoStatsProps.title}/>
        <TextField source={MissionInfoStatsProps.description}/>
        <TextField source={MissionInfoStatsProps.notificationType}/>
        <TextField source={MissionInfoStatsProps.dangers}/>
        <LocationChipField source={MissionInfoStatsProps.rescueLoc}/>
        <LocationChipField source={MissionInfoStatsProps.pickupLoc}/>
        <LocationChipField source={MissionInfoStatsProps.destinationLoc}/>
        <BooleanField source={MissionInfoStatsProps.isWinchRescue}/>
        <BooleanField source={MissionInfoStatsProps.isRsh}/>
        <BooleanField source={MissionInfoStatsProps.isAcknowledged}/>
        <DateField source={MissionInfoStatsProps.createdAt} showTime locales="de-CH" options={dateTimeOptionsDeCH}/>
    </Datagrid>
);

export type MissionInfoStats = RaRecord & {
    id: number;
    missionId: number;
    title: string;
    description: string;
    notificationType: MissionNotificationType,
    dangers: string;
    rescueLoc: MissionLocationWithIsRough;
    pickupLoc?: MissionLocation;
    destinationLoc?: MissionLocation;
    isWinchRescue: boolean;
    isRsh: boolean;
    isAcknowledged: boolean;
    createdAt: number;
};

export const MissionInfoStatsProps: Props2String<MissionInfoStats> = {
    id: "id",
    missionId: "missionId",
    title: "title",
    description: "description",
    notificationType: "notificationType",
    dangers: "dangers",
    rescueLoc: "rescueLoc",
    pickupLoc: "pickupLoc",
    destinationLoc: "destinationLoc",
    isWinchRescue: "isWinchRescue",
    isRsh: "isRsh",
    isAcknowledged: "isAcknowledged",
    createdAt: "createdAt"
};

export const isMissionLocation = (obj: any): obj is MissionLocation =>
    obj && typeof obj === "object" && MissionLocationProps.coordinate in obj;

export const isMissionLocationWithIsRough = (obj: any): obj is MissionLocationWithIsRough =>
    isMissionLocation(obj) && MissionLocationWithIsRoughProps.isLocationRough in obj;

export type MissionLocation = {
    coordinate: Coordinate;
};

export const MissionLocationProps: Props2String<MissionLocation> = {
    coordinate: "coordinate",
};

export type MissionLocationWithIsRough = MissionLocation & {
    isLocationRough: boolean;
};

export const MissionLocationWithIsRoughProps: Props2String<MissionLocationWithIsRough> = {
    coordinate: "coordinate",
    isLocationRough: "isLocationRough"
};
