import Typography from "@mui/material/Typography";
import { flatMap, groupBy, orderBy, values } from "lodash-es";
import * as React from "react";
import {
    BooleanField,
    Datagrid,
    DateField,
    DateTimeInput,
    Exporter,
    GetListResult,
    List,
    NumberField,
    Pagination,
    RaRecord,
    ReferenceField,
    ReferenceManyField,
    SelectInput,
    Show,
    SimpleShowLayout,
    TextField,
    TextInput,
    Translate,
    useTranslate
} from "react-admin";
import { csvExport } from "../customComponents/PagedExportButtonWithFilter";
import {
    dateTimeOptionsDeCH,
    ORDER,
    Props2String,
    RequiredOnly,
    StatsWithExportListActions,
    toDeCHLocaleString,
    viewRedirect
} from "./Common";
import {
    MissionInfoStats,
    MissionInfoStatsDatagrid,
    MissionInfoStatsProps,
    MissionInfoStatsResource
} from "./MissionInfoStats";
import { RescueVehicleProps, RescueVehiclesResource } from "./RescueVehicles";

export const MissionStatsResource = "stats/missions";
export const MissionStats2InfosResource = `${MissionStatsResource}/missioninfos`;

export const MissionStatsList = () => {
    const translate = useTranslate();
    const filters = missionStatsFilters(translate);
    return (
        <List
            perPage={25}
            empty={false}
            filters={filters}
            exporter={missionStatsExporter}
            sort={{field: MissionStatsProps.startedAt, order: ORDER.DESC}}
            actions={<StatsWithExportListActions filters={filters}/>}
        >
            <Datagrid
                bulkActionButtons={false}
                rowStyle={() => ({height: "2.25rem"})}
                rowClick="show"
            >
                <NumberField source={MissionStatsProps.id}/>
                <TextField source={MissionStatsProps.elsEventId}/>
                <TextField source={MissionStatsProps.elsDispatchId}/>
                <ReferenceField
                    source={MissionStatsProps.rescueVehicleId}
                    reference={RescueVehiclesResource}
                    link={viewRedirect.show}
                >
                    <TextField source={RescueVehicleProps.name}/>
                </ReferenceField>
                <DateField source={MissionStatsProps.startedAt} showTime locales="de-CH" options={dateTimeOptionsDeCH}/>
                <DateField source={MissionStatsProps.finishedAt} showTime locales="de-CH"
                           options={dateTimeOptionsDeCH}/>
                <TextField source={MissionStatsProps.initialNotificationType}/>
                <BooleanField source={MissionStatsProps.isAcknowledged}/>
                <DateField source={MissionStatsProps.acknowledgedAt} showTime locales="de-CH"
                           options={dateTimeOptionsDeCH}/>
            </Datagrid>
        </List>
    );
};

const missionStatsFilters: (translate: Translate) => JSX.Element[] = (translate) => {
    const field2LabelAndSource = (field: keyof MissionStatsFilterFields) => ({
        label: translate(`da.stats.mission.filter.${field}`),
        source: field,
        style: {minWidth: "10rem", marginLeft: "4px", marginRight: "4px"}
    });

    return [
        <TextInput
            {...field2LabelAndSource(MissionStatsFilterFieldsProps.query)}
            alwaysOn
        />,
        <DateTimeInput
            {...field2LabelAndSource(
                MissionStatsFilterFieldsProps.startedAfter
            )}
        />,
        <DateTimeInput
            {...field2LabelAndSource(
                MissionStatsFilterFieldsProps.finishedBefore
            )}
        />,
        <SelectInput
            {...field2LabelAndSource(
                MissionStatsFilterFieldsProps.initialNotificationType
            )}
            choices={notificationTypeChoices}
        />
    ];
};

const missionStatsExporter: Exporter = (
    listResult: GetListResult<MissionStats>,
    fetchRelatedRecords
) => {
    return Promise.all([
        listResult.data,
        fetchRelatedRecords(
            listResult.data,
            MissionStatsProps.id,
            MissionStats2InfosResource
        ) as PromiseLike<MissionInfoStats[]>
    ]).then(([allMissionStats, relatedMissionInfoStats]) => {
        const missionId2Infos = groupBy(relatedMissionInfoStats, "missionId");

        const allMissionStatsForExport = orderBy(
            flatMap(allMissionStats, (missionStats) => {
                // do not include id, rescueVehicleId, isAcknowledged, startedAt, finishedAt, acknowledgedAt
                const {
                    id: missionId,
                    isAcknowledged,
                    startedAt,
                    finishedAt,
                    acknowledgedAt,
                    ...missionStatsRest
                } = missionStats;

                return flatMap(
                    missionId2Infos[missionId],
                    (missionInfoStats) => {
                        // do not include id, rescueLocation, pickupLocation, destination, timestamp
                        const {
                            id: missionInfoId,
                            rescueLoc,
                            pickupLoc,
                            destinationLoc,
                            createdAt,
                            ...missionInfoStatsRest
                        } = missionInfoStats;
                        return {
                            ...missionStatsRest,
                            ...missionInfoStatsRest,
                            startedAt: toDeCHLocaleString(startedAt),
                            finishedAt: toDeCHLocaleString(finishedAt),
                            acknowledgedAt: toDeCHLocaleString(acknowledgedAt),
                            missionInfoId: missionInfoId,
                            isRescueLocationRough: rescueLoc.isLocationRough,
                            rescueLocationLat: rescueLoc.coordinate.latitude,
                            rescueLocationLon: rescueLoc.coordinate.longitude,
                            pickupLocationLat: pickupLoc?.coordinate.latitude,
                            pickupLocationLon: pickupLoc?.coordinate.longitude,
                            destinationLat: destinationLoc?.coordinate.latitude,
                            destinationLon: destinationLoc?.coordinate.longitude,
                            updateTimestamp: toDeCHLocaleString(createdAt)
                        } as MissionStatsWithInfosExport;
                    }
                );
            }),
            [
                MissionStatsWithInfosExportProps.missionId,
                MissionStatsWithInfosExportProps.missionInfoId
            ],
            ["desc", "desc"]
        );

        csvExport(
            allMissionStatsForExport,
            {headers: values(MissionStatsWithInfosExportProps)},
            "statistiken_einsaetze"
        );
    });
};

const MissionStatsExpandLabel = () => {
    const translate = useTranslate();
    return (
        <Typography variant="h3" gutterBottom>
            {translate("da.stats.mission.expand.label")}
        </Typography>
    );
};

export const MissionStatsShow = () => {
    return (
        <Show>
            <SimpleShowLayout>
                <ReferenceManyField
                    pagination={<Pagination/>}
                    source={MissionStatsProps.id}
                    reference={MissionInfoStatsResource}
                    target={MissionInfoStatsProps.missionId}
                    sort={{
                        field: MissionInfoStatsProps.createdAt,
                        order: ORDER.DESC
                    }}
                    label={<MissionStatsExpandLabel/>}
                    /*sx={{
                        display: "flex",
                        "& > div": {
                            flex: "1 1 auto"
                        }
                    }}*/
                >
                    <MissionInfoStatsDatagrid/>
                </ReferenceManyField>
            </SimpleShowLayout>
        </Show>
    );
};

type MissionStats = RaRecord & {
    id: number;
    rescueVehicleId: number;
    rescueVehicleName: string;
    startedAt: number;
    finishedAt: number;
    initialNotificationType: MissionNotificationType;
    elsDispatchId: string;
    elsEventId: string;
    isAcknowledged: boolean;
    acknowledgedAt: number;
};

const MissionStatsProps: Props2String<MissionStats> = {
    id: "id",
    rescueVehicleId: "rescueVehicleId",
    rescueVehicleName: "rescueVehicleName",
    startedAt: "startedAt",
    finishedAt: "finishedAt",
    initialNotificationType: "initialNotificationType",
    elsDispatchId: "elsDispatchId",
    elsEventId: "elsEventId",
    isAcknowledged: "isAcknowledged",
    acknowledgedAt: "acknowledgedAt"
};

export type MissionNotificationType = "INFO" | "ALARM";

export const notificationTypeChoices: {
    id: MissionNotificationType;
    name: MissionNotificationType;
}[] = [
    {id: "INFO", name: "INFO"},
    {id: "ALARM", name: "ALARM"}
];

type MissionStatsWithInfosExport = Omit<RequiredOnly<MissionStats>,
    | "id"
    | "startedAt"
    | "finishedAt"
    | "isAcknowledged"
    | "acknowledgedAt"
    | "initialNotificationType"> &
    Omit<RequiredOnly<MissionInfoStats>,
        "id" | "createdAt" | "rescueLoc" | "pickupLoc" | "destinationLoc"> & {
    missionInfoId: number;
    startedAt: string;
    finishedAt: string;
    acknowledgedAt: string;
    updateTimestamp: string;
    isRescueLocationRough: boolean;
    rescueLocationLat: number;
    rescueLocationLon: number;
    pickupLocationLat: number;
    pickupLocationLon: number;
    destinationLat: number;
    destinationLon: number;
};

const MissionStatsWithInfosExportProps: Props2String<MissionStatsWithInfosExport> =
    {
        missionId: "missionId",
        rescueVehicleId: "rescueVehicleId",
        rescueVehicleName: "rescueVehicleName",
        startedAt: "startedAt",
        finishedAt: "finishedAt",
        elsDispatchId: "elsDispatchId",
        elsEventId: "elsEventId",
        missionInfoId: "missionInfoId",
        notificationType: "notificationType",
        title: "title",
        description: "description",
        dangers: "dangers",
        isRescueLocationRough: "isRescueLocationRough",
        rescueLocationLat: "rescueLocationLat",
        rescueLocationLon: "rescueLocationLon",
        pickupLocationLat: "pickupLocationLat",
        pickupLocationLon: "pickupLocationLon",
        destinationLat: "destinationLat",
        destinationLon: "destinationLon",
        isWinchRescue: "isWinchRescue",
        isRsh: "isRsh",
        isAcknowledged: "isAcknowledged",
        acknowledgedAt: "acknowledgedAt",
        updateTimestamp: "updateTimestamp"
    };

type MissionStatsFilterFields = {
    query: string;
    [MissionStatsProps.initialNotificationType]: MissionNotificationType;
    startedAfter: number;
    finishedBefore: number;
};

const MissionStatsFilterFieldsProps: Props2String<MissionStatsFilterFields> = {
    query: "query",
    initialNotificationType: "initialNotificationType",
    startedAfter: "startedAfter",
    finishedBefore: "finishedBefore"
};
