import { stringify } from "query-string";
import { AuthProvider } from "react-admin";

const LOGIN_URL = process.env.REACT_APP_API_URL + "/login";
const LOGOUT_URL = process.env.REACT_APP_API_URL + "/logout";

const LIVE_AUTHORITY = "LIVE_AUTHORITY";
export const OPERATOR_AUTHORITY = "OPERATOR_AUTHORITY";
export const PHONE_AUTHORITY = "PHONE_AUTHORITY";
export const ENTITIES_AUTHORITY = "ENTITIES_AUTHORITY";
export const STATS_AUTHORITY = "STATS_AUTHORITY";
export const SETTINGS_AUTHORITY = "SETTINGS_AUTHORITY";

const authProvider: AuthProvider = {
    // called when the user attempts to log in
    login: async (params: { username: string, password: string, workstationId: string }) => {
        const username = params.username;
        const password = params.password;
        const workstationId = params.workstationId;
        const request = new Request(LOGIN_URL, {
            method: "POST",
            credentials: "include",
            body: `${stringify({password, username})}`,
            headers: new Headers({
                "Accept": "application/json",
                "Content-Type": "application/x-www-form-urlencoded",
                "Referrer-Policy": "unsafe-url"
            })
        });

        let response = await fetch(request);
        if (response.status < 200 || response.status >= 300) {
            return Promise.reject(response.statusText);
        }

        const authorities = response.headers.get("X-User-Authorities");
        // must have LIVE_AUTHORITY to be able to log in via GUI
        if (!authorities || !authorities.split(",").includes(LIVE_AUTHORITY)) {
            return Promise.reject("ra.auth.sign_in_error");
        }

        sessionStorage.setItem("username", username);
        sessionStorage.setItem("authorities", authorities);
        sessionStorage.setItem("workstationId", workstationId);

        return Promise.resolve();
    },
    // called when the user clicks on the logout button
    logout: async () => {
        const request = new Request(LOGOUT_URL, {
            method: "DELETE",
            credentials: "include",
            headers: new Headers({
                Accept: "application/json",
                "Referrer-Policy": "unsafe-url"
            })
        });
        try {
            let response = await fetch(request);
            return await response.json();
        } catch (reason) {
            console.log(reason);
        } finally {
            sessionStorage.removeItem("username");
            sessionStorage.removeItem("authorities");
            sessionStorage.removeItem("workstationId");
        }
    },
    // called when the API returns an error
    checkError: ({status}: { status: number }) => {
        if (status === 401 || status === 403) {
            sessionStorage.removeItem("username");
            sessionStorage.removeItem("authorities");
            sessionStorage.removeItem("workstationId");
            return Promise.reject();
        }
        return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: () => {
        return sessionStorage.getItem("username") && sessionStorage.getItem("authorities") ? Promise.resolve()
            : Promise.reject();
    },
    // called when the user navigates to a new location, to check for permissions / authorities
    getPermissions: () => {
        const authorities = sessionStorage.getItem("authorities");
        return authorities ? Promise.resolve(authorities.split(",")) : Promise.reject();
    }
};

export default authProvider;
