import Chip from "@mui/material/Chip";
import { get } from "lodash-es";
import * as React from "react";
import { useRecordContext, useTranslate } from "react-admin";
import { isMissionLocation, isMissionLocationWithIsRough, MissionLocation } from "../resources/MissionInfoStats";

export const LocationChipField: React.FC<{ source: string }> = ({source}) => {
    const record = useRecordContext();
    const location = source && get(record, source) as MissionLocation;
    const translate = useTranslate();

    if (isMissionLocation(location)) {
        const latitude = parseFloat(location.coordinate.latitude.toFixed(5)); // truncate to 5 decimal places
        const longitude = parseFloat(location.coordinate.longitude.toFixed(5)); // truncate to 5 decimal places
        const locationRoughSuffix = `${isMissionLocationWithIsRough(location) ? ` (${translate("da.coordinates.rough")})` : ""}`;
        const locationString = `${latitude}, ${longitude}${locationRoughSuffix}`;
        return (
            <Chip
                sx={{margin: 4, cursor: "inherit"}}
                label={locationString}
            />
        );
    }
    return null;
};

export default LocationChipField;
